// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from 'jquery';
global.$ = $
global.jQuery = $
require("jquery")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import Popper from 'popper.js'
import 'bootstrap'
import "channels"
require("packs/common_scripts")
require("packs/input_qty")
require("packs/functions")
require("packs/jquery.sliderPro")
require("packs/jquery.finaltilesgallery")
require("packs/lightbox2")
require("packs/include")
require("trix")
require("@rails/actiontext")

Rails.start()
Turbolinks.start()
ActiveStorage.start()
